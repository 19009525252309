import * as React from "react"
import Jobs from "../components/jobs"

import Layout from "../components/layout"
import Seo from "../components/seo"

const JobsPage = () => (
  <Layout>
    <Seo title="Home" />

    <Jobs />
  </Layout>
)

export default JobsPage
