/* This example requires Tailwind CSS v2.0+ */
import React from 'react';

import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
    {
        question: "Monteur/ 80% - 100%",
        answer: `
            Wir sind ein dynamisches Unternehmen mit Hauptsitz in Emmenbrücke und verfügen über eine 
            hauseigene Ausstellung und Büroräumlichkeiten in Zug. Wir sind spezialisiert auf die 
            Beschaffung und Montage von Fenster, Türen, Fassaden, Tore, Geländer sowie allgemeine Metallbauarbeiten. Wir handeln lösungsorientiert mit einem gespitzten Kundenfokus. Das 
            entgegengebrachte Vertrauen behandeln wir aufrichtig, ehrlich und führen unsere Aufgabe
            präzise, mit der geforderten Dynamik aus.
            
            Zur Unterstützung unserer Montageteams suchen wir per sofort oder nach Vereinbarungmotivierte Persönlichkeiten (m/w) als Monteure.
        `,
        pdfPath: '#'
    },
    // More questions...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Jobs() {
    return (
        <div className="bg-gray-50 h-screen">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">Jobs</h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt className="text-lg">
                                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                <span className="font-medium text-gray-900">{faq.question}</span>
                                                <span className="ml-6 h-7 flex items-center">
                                                    <ChevronDownIcon
                                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base text-gray-500">{faq.answer}</p>
                                            <a className="bg-blue-500 py-4 px-8 inline-block" href={faq} download={'Monteur'}> Stellenanzeige herunterladen </a>

                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
